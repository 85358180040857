<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :btnFlag="btnFlag"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
    >
    </app-list>

    <el-dialog
      title="发货"
      :visible.sync="dialogShow"
      width="1000px"
      @close="dialogShow = false"
    >
      <el-form :model="form" ref="ruleForm" label-width="100px">
        <el-form-item label="订单号" prop="id">
          <el-input v-model="form.id" size="small" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="物流公司" prop="trackingName">
          <el-select
            v-model="form.trackingName"
            size="small"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.parameterName"
              :value="item.parameterName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号" prop="trackingNumber">
          <el-input
            v-model="form.trackingNumber"
            placeholder="请输入账号"
            size="small"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
  
  <script>
import api from "@/api";

export default {
  data() {
    let self = this;
    return {
      form: { id: "", trackingName: "", trackingNumber: "" },
      dialogShow: false,
      remote: api.common,
      typeList: [],
      apiName: "/order",
      searchVal: "",
      bntLoading: false,
      btnFlag: { addShow: false, delShow: false },
      searchQuery: { siteName: "", siteCode: "" },
      rules: {
        //传递给APPlist的表单验证
      },
      props: [
        {
          label: "订单号",
          prop: "id",
          align: "center",
          width: "200px",
        },

        {
          label: "买家",
          prop: "purchaser",
          align: "center",
        },
        {
          label: "总价(元)",
          prop: "totalPrice",
          align: "center",
        },
        {
          label: "配送方式",
          prop: "distributionMode",
          align: "center",
        },
        {
          label: "收货人",
          prop: "consignee",
          align: "center",
        },
        {
          label: "电话",
          prop: "phone",
          align: "center",
        },
        {
          label: "店铺",
          prop: "storeId",
          align: "center",
        },

        {
          label: "订单状态",
          prop: "orderStatus",
          align: "center",
          type: "html",
          formatter: function (row) {
            const map = [
              { color: "#409EFF", text: "待支付" },
              { color: "#909399", text: "待发货" },
              { color: "#409EFF", text: "已发货" },
              { color: "#67C23A", text: "已收货" },
            ];

            const refundStatusMap = [
              { color: "#409EFF", text: "待支付" },
              { color: "#666", text: "退款中" },
              { color: "#666", text: "已退款" },
            ];
            if (row.refundStatus > 0) {
              return (
                "<span style='color:" +
                refundStatusMap[row.refundStatus].color +
                "'>" +
                refundStatusMap[row.refundStatus].text +
                "</span>"
              );
            } else {
              return (
                "<span style='color:" +
                map[row.orderStatus].color +
                "'>" +
                map[row.orderStatus].text +
                "</span>"
              );
            }
          },
        },

        {
          label: "storeId",
          prop: "storeId",
          align: "center",
        },
        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 200,
          type: "operationLink",
          formatter: function (row) {
            let buttonList = [];

            if (row.orderStatus == 1) {
              buttonList = [
                {
                  text: "详情",
                  size: "mini",
                  data: row,
                  show: true,
                  icon: "el-icon-edit",
                  type: "primary",
                  handler: function () {},
                },
                {
                  text: "发货",
                  size: "mini",
                  data: row,
                  show: true,
                  icon: "el-icon-shopping-cart-2",
                  type: "primary",
                  handlerType: "remove",
                  handler: function () {
                    self.deliverGoods(row.id);
                  },
                },
              ];
            } else {
              buttonList = [
                {
                  text: "详情",
                  size: "mini",
                  data: row,
                  show: true,
                  icon: "el-icon-edit",
                  type: "primary",
                  handler: function () {},
                },
              ];
            }

            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "图片名称",
          type: "input",
          prop: "name",
        },
        {
          label: "链接",
          type: "input",
          prop: "link",
        },
        {
          label: "类型",
          type: "select",
          prop: "type",
          selectData: [
            {
              label: "幻灯片",
              value: 0,
            },
            {
              label: "广告图",
              value: 1,
            },
            {
              label: "导航图",
              value: 2,
            },
          ],
        },

        {
          label: "图片",
          type: "upload",
          prop: "address",
          limit: 1,
        },
      ],
    };
  },
  created() {
  
  },
  mounted() {
    this.getTypeList();
  },
  methods: {
    deliverGoods(id) {
      this.form.id = id;
      this.dialogShow = true;
    },
    async getTypeList() {

      try {
        let res = await api.common.commonGet({
          apiName: "/systemDataDictionaryItem",
          systemDataDictionaryId: "1597521595575451649",
        });
        this.typeList = res.data.data;
      } catch (error) {
        console.log(error);
        // this.$message.error(error);
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
  